import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './styles.scss';
import logo from '../Img/logo-min.png';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, IconButton, useDisclosure, } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
const NavBarUI = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const goBack = () => {
        // eslint-disable-next-line no-restricted-globals
        history.back();
    };
    return (_jsxs("div", Object.assign({ className: 'navbar' }, { children: [_jsxs("div", Object.assign({ className: 'nav__wrapper' }, { children: [_jsx("div", Object.assign({ className: 'navbar__icon' }, { children: _jsx("span", Object.assign({ onClick: goBack }, { children: _jsx("img", { src: logo, alt: 'No found' }) })) })), _jsxs("ul", Object.assign({ className: 'navbar__options' }, { children: [_jsx("li", { children: _jsx("a", Object.assign({ href: '#project' }, { children: "PROYECTO" })) }), _jsx("li", { children: _jsx("a", Object.assign({ href: '#lotes' }, { children: "LOTES" })) }), _jsx("li", { children: _jsx("a", Object.assign({ href: '#contact' }, { children: "CONTACTO" })) })] })), _jsx(IconButton, { className: 'navbar__burger-button', "aria-label": 'Options', onClick: onOpen, icon: _jsx(HamburgerIcon, {}), variant: 'outline' })] })), _jsxs(Drawer, Object.assign({ placement: 'top', onClose: onClose, isOpen: isOpen, size: 'full', returnFocusOnClose: false }, { children: [_jsx(DrawerOverlay, {}), _jsxs(DrawerContent, Object.assign({ className: 'navbar__burger' }, { children: [_jsx(DrawerCloseButton, {}), _jsx(DrawerHeader, Object.assign({ className: 'navbar-burger__header' }, { children: _jsx("span", Object.assign({ onClick: goBack }, { children: _jsx("img", { src: logo, alt: 'No found' }) })) })), _jsx(DrawerBody, { children: _jsxs("ul", Object.assign({ className: 'navbar-burger__options' }, { children: [_jsx("li", { children: _jsx("a", Object.assign({ href: '#project', onClick: onClose }, { children: "PROYECTO" })) }), _jsx("li", { children: _jsx("a", Object.assign({ href: '#lotes', onClick: onClose }, { children: "LOTES" })) }), _jsx("li", { children: _jsx("a", Object.assign({ href: '#contact', onClick: onClose }, { children: "CONTACTO" })) })] })) })] }))] }))] })));
};
export default NavBarUI;
