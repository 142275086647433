import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import oxa from './img/oxa.png';
import huancabamba from './img/huancabamba.png';
import reserva from './img/reserva.png';
import logo from './img/oxapampa_historia.svg';
import './styles.scss';
import { useState } from 'react';
const History = () => {
    const [focus, setFocus] = useState(1);
    return (_jsxs("div", Object.assign({ className: 'history' }, { children: [_jsxs("div", Object.assign({ className: 'history__title' }, { children: [_jsx("img", { src: logo, alt: '', className: 'title' }), _jsx("hr", {})] })), _jsxs("div", Object.assign({ className: 'history__slider' }, { children: [_jsxs("article", Object.assign({ className: 'history-slider__portail' }, { children: [_jsxs("div", Object.assign({ className: focus == 1
                                    ? 'history-slider-portail__img history-slider-portail__img--focus'
                                    : 'history-slider-portail__img' }, { children: [_jsx("p", Object.assign({ className: 'history_number' }, { children: "01" })), _jsx("img", { src: oxa, alt: 'Not image', onClick: () => {
                                            setFocus(1);
                                        } })] })), _jsxs("div", Object.assign({ className: focus == 2
                                    ? 'history-slider-portail__img history-slider-portail__img--focus'
                                    : 'history-slider-portail__img' }, { children: [_jsx("p", Object.assign({ className: 'history_number' }, { children: "02" })), _jsx("img", { src: reserva, alt: 'Not image', onClick: () => {
                                            setFocus(2);
                                        } })] })), _jsxs("div", Object.assign({ className: focus == 3
                                    ? 'history-slider-portail__img history-slider-portail__img--focus'
                                    : 'history-slider-portail__img' }, { children: [_jsx("p", Object.assign({ className: 'history_number' }, { children: "03" })), _jsx("img", { src: huancabamba, alt: 'Not image', onClick: () => {
                                            setFocus(3);
                                        } })] }))] })), _jsxs("article", Object.assign({ className: 'history-slider__comment' }, { children: [_jsxs("div", Object.assign({ className: focus == 1 ? 'comment' : 'comment--hide' }, { children: [_jsx("p", Object.assign({ className: 'comment--title' }, { children: "Desde su fundaci\u00F3n en 1891 Oxapampa es el centro tur\u00EDstico m\u00E1s importante de la Selva Central." })), _jsx("p", { children: "Dentro de su historia destacan los Yaneshas y Austro-Alemanes, fundadores y creadores de una s\u00EDntesis cultural \u00FAnica en el Per\u00FA. La arquitectura, la m\u00FAsica, la gastronom\u00EDa y la cultura son aspectos que siguen maravillando y cautivando a todos los ciudadanos que arriban a este para\u00EDso natural." })] })), _jsxs("div", Object.assign({ className: focus == 2 ? 'comment' : 'comment--hide' }, { children: [_jsx("p", { children: "La Unesco en el 2010 consider\u00F3 a la Reserva de Bi\u00F3sfera Oxapampa-Ash\u00E1ninka-Yanesha como uno de los 25 nuevos refugios de preservaci\u00F3n de la naturaleza en el planeta. Destacando la belleza de los paisajes y la biodiversidad de su flora y fauna." }), _jsx("p", Object.assign({ className: 'comment--slogan' }, { children: '<<Una ciudad soñada para ti y para seguir cuidando el planeta>>' }))] })), _jsxs("div", Object.assign({ className: focus == 3 ? 'comment' : 'comment--hide' }, { children: [_jsx("p", { children: "Finalmente, Huancabamba es el eje econ\u00F3mico m\u00E1s importante de la actividad agr\u00EDcola de la Provincia de Oxapampa, impulsado por la fertilidad de sus tierras y los paisajes naturales." }), _jsx("p", Object.assign({ className: 'comment--slogan' }, { children: '<<Deseamos acompañarte a realizar el mejor proyecto en una excelente ciudad>>' }))] }))] }))] }))] })));
};
export default History;
