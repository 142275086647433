import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import FooterUI from '../../CommonUI/Footer';
import SpinnerUI from '../../CommonUI/Spiner';
import About from '../Components/about';
import Banner from '../Components/banner';
import Contact from '../Components/contact';
import History from '../Components/history';
import Others from '../Components/others';
import Valley from '../Components/valley';
import './styles.scss';
const Home = () => {
    const [stateComponent, setStateComponent] = useState('form');
    if (stateComponent == 'loading') {
        return _jsx(SpinnerUI, {});
    }
    else {
        return (_jsxs("div", Object.assign({ className: 'home' }, { children: [_jsx(Banner, {}), _jsx(About, {}), _jsx(History, {}), _jsx(Valley, {}), _jsx(Others, {}), _jsx(Contact, {}), _jsx(FooterUI, {})] })));
    }
};
export default Home;
